<template>
  <section class="daily-report">
    <section class="daily-report-top">
      <div class="daily-select">
        <span style="padding-right: 20px;font-size:15px">生成时间:</span>
        <el-date-picker v-model="startDate" size="small" type="date" placeholder="选择日期" value-format="yyyy-MM-dd">
        </el-date-picker>
        <span style="padding-right: 10px;padding-left:10px;font-size:15px">至</span>
        <el-date-picker v-model="endDate" size="small" type="date" placeholder="选择日期" value-format="yyyy-MM-dd">
        </el-date-picker>
        <span style="padding-left: 20px;padding-right: 20px;font-size:15px">关键词:</span>
        <el-input style="width: 200px;" size="small" v-model="keyword" placeholder="请输入内容"></el-input>
        <el-button size="mini" type="primary" style="margin-left: 30px;" @click="selectDaily" :disabled="loading">查询</el-button>
      </div>
    </section>
    <section class="daily-report-content" v-loading="loading">
      <div class="operationTool" style="text-align: right;padding-bottom: 10px; padding-right: 20px">
<!--        <el-button plain size="mini" @click="createDaily">生成预览日报</el-button>-->
<!--        <el-button plain size="mini" @click="setTemplate">模板设置</el-button>-->
        <el-button @click="currentCProject = '', showUploadDialog = true" type="primary" size="small">上传报告</el-button>
      </div>
      <el-table :data="tableData" border tooltip-effect="dark" @selection-change="handleSelectionChange" element-loading-text="正在查询">
        <el-table-column  :resizable="false" type="selection" width="55" align="center">
        </el-table-column>
        <el-table-column  :resizable="false" prop="name" label="报告标题" align="center" show-overflow-tooltip>
        </el-table-column>
        <el-table-column  :resizable="false" prop="projectName" label="所属项目" width="180" align="center" show-overflow-tooltip>
        </el-table-column>
        <el-table-column  :resizable="false" prop="createDate" align="center" width="180" sortable label="生成时间" :formatter="formatDate">
        </el-table-column>
        <el-table-column  :resizable="false" align="center" width="300" label="操作">
          <template slot-scope="{ row }">
            <el-button type="text" @click="viewDaily(row.id)">预览
            </el-button>
            <el-button type="text" @click="downloadDaily(row.id)">下载
            </el-button>
            <el-button type="text" @click="deleteDaily(row.id)">删除
            </el-button>
            <el-button type="text" @click="toEmail(row.id)">发送到邮箱
            </el-button>
<!--            <el-button type="text" @click="toPushDaily(row.id)">日报推送-->
<!--            </el-button>-->
          </template>
        </el-table-column>
      </el-table>
      <section class="list-bottom">
        <span class="count">共{{count}}条记录</span>
        <el-pagination :current-page="currentPage" :page-sizes="[10, 20, 30, 50]" :page-size="limit" layout="total, sizes, prev, pager, next, jumper"
          @size-change="sizeChange" @current-change="currentChange" :total="count">
        </el-pagination>
      </section>
    </section>
    <el-dialog
      title="上传中"
      :visible.sync="showUploading"
      :show-close="false"
      width="30%">
      <span>
        <el-progress :text-inside="true" :stroke-width="26" :percentage="uploadPercent" :show-text="false"></el-progress>
      </span>

    </el-dialog>
    <el-dialog title="模板设置" :visible.sync="showTemplateDialog" top="3%" width="80%" center>
      <dailyTemplate ref="template" @visible="getVisible"></dailyTemplate>
    </el-dialog>
    <el-dialog title="生成日报" :visible.sync="showReportDialog" top="8%" width="80%" center>
      <dailyData @visible="getVisible"></dailyData>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="showDeleteDialog" top="12%" width="30%" center>
      <span class="prompt"><i class="el-icon-warning"></i>确定删除？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDeleteDialog = false">取 消</el-button>
        <el-button type="primary" @click="confirmDelete">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="推送日报到" :visible.sync="showPushDialog" top="12%" width="30%" center>
      <el-form >
          <el-form-item label="账号" >
            <el-select  placeholder="请选择账号">
              <el-option label="wankedichan" value=""></el-option>
              <el-option label="vanke" value=""></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showPushDialog = false">取 消</el-button>
          <el-button type="primary" @click="confirmPush">确 定</el-button>
        </span>
    </el-dialog>
    <el-dialog title="推送日报到" :visible.sync="showSendDialog" top="12%" width="30%" center>
      <div>
        <span class="demonstration">用户名</span>
        <el-cascader
          :options="options"
          :props="{ multiple: true }"
          v-model="selectUserEmail"
          clearable></el-cascader>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showSendDialog = false">取 消</el-button>
        <el-button type="primary" @click="confirmPush">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="上传报告" :visible.sync="showUploadDialog" width="410px" center>
      <div style="margin-bottom: 10px">
        <el-select style="width: 100%" v-model="currentCProject" placeholder="请选择对应终端项目">
          <el-option
            v-for="item in cpArray"
            :key="item.id"
            :label="item.pname"
            :value="item.id">
          </el-option>
        </el-select>
      </div>
      <el-upload
        style="width: 100%"
        drag
        accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx"
        class="upload-demo"
        :multiple="false"
        :http-request="addFile"
        action="no"
        :limit="1"
        :show-file-list="false"
        :file-list="fileList">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
    </el-dialog>
  </section>
</template>

<script>
import { selectDailyByDate, deleteDaily, pushDaily, pushDailyToEamil, viewDaily, getMonitorSubjectByCid } from '@/api/daily/report'
import { selectTemplate } from '@/api/daily/template'
import { getToken } from '@/utils/auth'
import { apiBaseURL } from '@/utils/request'
import { listUserByCid } from '@/api/settings/userManagement'
import dailyTemplate from './dailyTemplate'
import dailyData from './dailyData'
import axios from "axios";
import { listCP } from '@/api/cProject'
export default {
  components: {
    dailyTemplate, dailyData
  },
  data () {
    return {
      loading: false,
      showUploading: false,
      uploadPercent: 0,
      count: 0,
      status: '1',
      currentPage: 1,
      limit: 10,
      startDate: '',
      endDate: '',
      keyword: '',
      deleteId: '',
      showDeleteDialog: false,
      showReportDialog: false,
      showTemplateDialog: false,
      showPushDialog: false,
      showSendDialog: false,
      multipleSelection: [],
      selectUserEmail: [],
      selectToEmailReportId: '',
      props: { multiple: true, checkStrictly: true },
      options: [],
      tableData: [],
      fileList: [],
      showUploadDialog: false,
      currentCProject: '',
      cpArray: []
    }
  },
  methods: {
    async fetchCPList () {
      const res = await listCP()
      this.cpArray = res.data
    },
    uploadProgressPercent () {
      this.uploadPercent = 0
      var _this = this
      var t2 = window.setInterval(function() {
        if (_this.uploadPercent < 90) {
          _this.uploadPercent = _this.uploadPercent + 10
        }
      },2000)
    },
    // 格式化时间戳
    formatDate (row) {
      var time;
      if (typeof (row.createDate) === 'string' && row.createDate.indexOf('T') === -1) {
        time = row.createDate.replace(/-/g, '/').substr(0, row.createDate.indexOf('.'))
      }
      let date = new Date(time)
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      let hour = date.getHours()
      let min = date.getMinutes()
      let second = date.getSeconds()
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      if (hour < 10) {
        hour = '0' + hour
      }
      if (min < 10) {
        min = '0' + min
      }
      if (second < 10) {
        second = '0' + second
      }
      return year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + second
    },
    addFile (item) {
      if (!this.currentCProject) {
        this.$message.warning('必须选择一个终端项目才可以上传报告')
        this.fileList = []
        return
      }
      let fd = new FormData()
      // 传文件
      fd.append('file', item.file)
      fd.append('projectId', this.currentCProject)
      let requestConfig = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'token': getToken()
        }
      }
      let _this = this
      _this.showUploading = true
      this.uploadProgressPercent()
      axios.post(apiBaseURL + `/report/upload`, fd, requestConfig).then((res) => {
        this.fileList = []
        if (res.data.code === 0) {
          _this.uploadPercent = 100
          _this.showUploading = false
          _this.showUploadDialog = false
          _this.$message.success(res.data.msg)
          this.selectDaily()
        } else {
          _this.uploadPercent = 0
          _this.showUploading = false
          _this.$message.error(res.data.msg)
        }
      })
    },
    // 查询所有
    // 模板设置
    setTemplate () {
      this.showTemplateDialog = true
      this.$refs.template.initializeSelect();
    },
    getVisible (data) {
      this.showTemplateDialog = data
      this.showReportDialog = data
    },
    // 跳往日报生成页面
    async createDaily () {
      const res = await selectTemplate()
      console.log(res)
      if (res.code === 1) {
        this.$alert('您还未设置模板，请先设置模板', '提示', {
          confirmButtonText: '去设置',
          type: 'warning',
          callback: () => {
            this.showTemplateDialog = true
          }
        })
      } else {
        this.showReportDialog = true
      }
      // this.$router.push('dailyData')
    },
    // 日报推送
    toPushDaily (id) {
      try {
        pushDaily({ id: id })
        this.$message.warning('接口尚未完成')
        // this.showPushDialog = true
      } catch (e) {
        this.$message.error('服务器内部异常')
      }
    },
    // 推送dialog确定
    async confirmPush () {
      let selectUserEmailParams = []
      try {
        console.log(this.selectUserEmail, '177')
        if (this.selectUserEmail.length > 0) {
          for (var i = 0; i < this.selectUserEmail.length; i++) {
            for (var j = 0; j < this.selectUserEmail[i].length; j++) {
              selectUserEmailParams.push(this.selectUserEmail[i][j])
            }
          }
        } else {
          this.$message.warning('请选择需要发送的用户')
        }
        let params = { reportId: this.selectToEmailReportId, selUsers: selectUserEmailParams }
        console.log(params, '182')
        pushDailyToEamil(params)
        this.showPushDialog = false
        this.$message.success('发送成功')
      } catch (e) {
        this.$message.error('服务器内部异常')
      }
    },
    // 发送到邮箱
    async toEmail (id) {
      this.selectToEmailReportId = id
      const res = await listUserByCid()
      this.options = res.data
      this.showSendDialog = true
    },
    // 打开删除对话框
    deleteDaily (id) {
      console.log(id)
      this.deleteId = id
      this.showDeleteDialog = true
    },
    // 确认删除
    async confirmDelete () {
      const res = await deleteDaily({ id: this.deleteId })
      this.showDeleteDialog = false
      if (res.code === 0) {
        this.$message.success(res.msg)
      } else {
        this.$message.error(res.msg)
      }
      this.selectDaily()
    },
    // 下载
    downloadDaily (id) {
      try {
        let token = getToken()
        let down = document.createElement('a')
        down.href = apiBaseURL + '/report/downloadDaily?id=' + id + '&token=' + token
        down.click()
        this.$message.success('下载成功')
      } catch (error) {
        this.$message.error('服务器内部异常')
      }
    },
    async viewDaily (id) {
      this.$message.success('正在准备预览...')
      const res = await viewDaily({ id: id})
      if (res.code === 0) {
        this.$message.success('准备完成')
        if (res.msg.indexOf('.pdf')!=-1) {
          window.open(apiBaseURL+'/view/'+res.msg)
        } else {
          window.open('https://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(apiBaseURL + '/view/' + res.msg))
        }
      } else {
        this.$message.error('服务异常，无法预览该日报')
      }
    },
    async selectDaily () {
      this.loading = true
      this.currentPage = 1
      this.limit = 10
      try {
        const params = {
          startDate: this.startDate,
          endDate: this.endDate,
          keyword: this.keyword,
          pageSize: this.limit,
          current: this.currentPage
        }
        const res = await selectDailyByDate(params)
        this.tableData = res.data
        this.count = res.count
        this.loading = false
      } catch (e) {
        this.$message.error('服务器内部异常')
      }
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    async sizeChange (i) {
      this.currentPage = 1
      this.limit = i
      try {
        const res = await selectDailyByDate({
          pageSize: this.limit,
          current: this.currentPage
        })
        this.tableData = res.data
        this.count = res.count
      } catch (err) {
        console.log(err)
      }
    },
    async currentChange (i) {
      this.currentPage = i
      try {
        const res = await selectDailyByDate({
          pageSize: this.limit,
          current: this.currentPage
        })
        this.tableData = res.data
        this.count = res.count
      } catch (err) {
        console.log(err)
      }
    }
  },
  async mounted () {
    this.fetchCPList()
    try {
      const params = {
        pageSize: this.limit,
        current: this.currentPage
      }
      const res = await selectDailyByDate(params)
      this.tableData = res.data
      this.count = res.count
    } catch (err) {
      console.log(err)
    }
  }
}
</script>

<style lang="scss">
  .daily-report {
    width: 100%;
    height: 100%;

    .daily-report-top {
      height: 86px;
      border-radius: 2px;
      margin-bottom: 30px;
      border: 1px #eceaea solid;
      background: #ffffff;
      box-shadow: 1px 1px 3px 1px rgba(212, 211, 211, 0.2);

      .daily-select {
        padding: 28px 0px 0px 20px;

        .el-input--prefix .el-input__inner {
          height: 29px;
        }
      }
    }

    .daily-report-content {
      position: relative;
      border-radius: 2px;
      margin-bottom: 30px;
      padding: 20px;
      padding-right: 0;
      border: 1px #eceaea solid;
      background: #ffffff;
      box-shadow: 1px 1px 3px 1px rgba(212, 211, 211, 0.2);


      .el-table {
        width: calc(100% - 20px);
        margin-bottom: 50px;

        th.gutter {
          display: table-cell !important;
        }

        &.el-table th>.cell {
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &.el-table--border td:first-child .cell {
          padding-right: 10px !important;
        }
        .el-button.el-button--text{
          color: #3472c7;
        }
      }

      .list-bottom {
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: 20px;
        left: 20px;
        right: 20px;

        .count {
          line-height: 32px;
        }
      }

      .el-button--mini {
        padding: 7px 7px;
      }
    }

    .prompt {
      height: 28px;
      display: flex;
      align-items: center;

      .el-icon-warning {
        font-size: 28px;
        color: #FF8B35;
        margin-right: 12px;
      }
    }
    .el-dialog__header{
      text-align: left;
    }
  }
</style>
